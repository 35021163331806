export default [
  {
    path: 'banner',
    label: 'Home',
    offset: -30,
  },
  {
    path: 'features',
    label: 'Hakkımızda',
    offset: 0,
  },
  {
    path: 'services',
    label: 'Hizmetlerimiz',
    offset: -40,
  },
  {
    path: 'pricing',
    label: 'Özellikler',
    offset: -70,
  },
  {
    path: 'support',
    label: 'Destek',
    offset: -70,
  },
];
